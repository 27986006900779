import React, { useState, useEffect } from 'react'
import './Dashboard.css';
import { API_ENDPOINTS } from '../settings.js';
import Notification, { notifyInfo, notifySuccess, notifyWarning, notifyError } from '../popups/Notification';
import ClipLoader from 'react-spinners/ClipLoader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Invocie = () => {
  
  let [authTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
  const [invoiceSummary, setInvoiceSummary] = useState([]);
  const [distinctInvoiceDates, setDistinctInvoiceDates] = useState([]);
  const [invoiceSelectedDate, setInvoiceSelectedDate] = useState('');
  const [summarySelectedDate, setSummarySelectedDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [summaryCount, setSummaryCount] = useState(0);
  const [stores, setStores] = useState([]);
  const [verifiedInvoices, setVerifiedInvoices] = useState(0);
  const [filters, setFilters] = useState({
    name: '',
    period: ''
  });
  const [searchInvoiceId, setSearchInvoiceId] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const getStoreNameById = (storeId) => {
    const store = stores.find(store => store.store_id === storeId);
    return store ? store.store_name : 'Unknown Store';
  };
  const filteredInvoiceSummary = invoiceSummary.filter((summary) => {
    return (
      (filters.name === '' || getStoreNameById(summary.invoice_store_id).toLowerCase().includes(filters.name.toLowerCase())) &&
      (filters.period === '' || summary.invoice_billing_period.toLowerCase().includes(filters.period.toLowerCase())) &&
      (searchInvoiceId === '' || summary.invoice_id.toString().includes(searchInvoiceId)) &&
      (searchStatus === '' || summary.invoice_status.toLowerCase().includes(searchStatus.toLowerCase()))
    );
  });
  const [editingStatusId, setEditingStatusId] = useState(null);
  const statusOptions = ['CREATED', 'VERIFIED', 'SEND', 'PAID', 'CANCELLED'];
  useEffect(() => {
    InvoiceData();
  }, [authTokens]);
  
  
  const InvoiceData = async () => {
    
    try {
      let response = await fetch(API_ENDPOINTS.INVOICE, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        }
      });
      
      let data = await response.json();
      console.log(data)
      setInvoiceSummary(data.invoice_data);
      setDistinctInvoiceDates(data.distinct_invoice_dates);
      setSummaryCount(data.summary_count);
      setStores(data.stores);
      setVerifiedInvoices(data.verified_invoices);
        if (data.distinct_invoice_dates.length > 0) {
          setInvoiceSelectedDate(data.distinct_invoice_dates[0]);
          setSummarySelectedDate(data.distinct_invoice_dates[0]);
        }
    } catch (error) {
      notifyError("Failed to fetch data.");
    } 
  }
  const GenerateInvoice = async () => {
    let keyword = "Create";
    if (summaryCount === 0) {
      notifyInfo("No data to create invoice.");
      return;
    } else {
      setIsLoading(true);
    }
  
    try {
      let response = await fetch(API_ENDPOINTS.INVOICE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify({ keyword })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        notifyError("Failed to generate invoice: " + errorData.error);
      } else {
        notifySuccess("Invoice generated successfully.");
        InvoiceData();
      }
    } catch (error) {
      notifyError("Failed to generate invoice: " + error.message);
    } finally {
      setIsLoading(false);
    }
  }
  const GenerateReport = async () => {
    let keyword = "Invoice_Report";
    let selected_invoice_date = invoiceSelectedDate;
    let response = await fetch(API_ENDPOINTS.INVOICE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      },
      body: JSON.stringify({ keyword, selected_invoice_date })
    });

    if (!response.ok) {
      notifyError("Failed to create invoice report.");
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'invoice_report.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    notifySuccess("Invoice report created successfully.");
}
  const SendVerifiedEmails = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to send the verified emails?</p>
            <div className='custom-ui-buttons'>
              <button
                onClick={async () => {
                  let keyword = "Send_Verified_Emails";
                  let selected_invoice_date = invoiceSelectedDate;
                  let response = await fetch(API_ENDPOINTS.INVOICE, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${authTokens.access}`
                    },
                    body: JSON.stringify({ keyword, selected_invoice_date })
                  });
                  if(!response.ok){
                    notifyError("Failed to send verified emails.");
                  }
                  else{
                    notifySuccess("Verified emails sent successfully.");
                    InvoiceData();
                  }
                  onClose();
                }}
                className='custom-ui-button custom-ui-button-yes'
              >
                Yes, Send it!
              </button>
              <button 
                onClick={onClose} 
                className='custom-ui-button custom-ui-button-no'
              >
                No
              </button>
            </div>
          </div>
        );
      }
    });
  }
  const SendTextMessage = async () => {
    let keyword = "Send_Text_Message";
    let selected_summary_date = summarySelectedDate;
    try {
      let response = await fetch(API_ENDPOINTS.INVOICE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify({ keyword, selected_summary_date })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error === "No stores found for the selected date") {
          notifyWarning("No stores found for the selected date.");
        } else {
          notifyError("Failed to send text messages.");
        }
      } else {
        notifySuccess("Text messages sent successfully.");
        InvoiceData();
      }
    } catch (error) {
      notifyError("Error: " + error);
    }
  }
  const GeneratePADReport = async () => {
    let keyword = "PAD_report";
    let selected_summary_date = summarySelectedDate;
    let response = await fetch(API_ENDPOINTS.INVOICE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      },
      body: JSON.stringify({ keyword, selected_summary_date })
    });

    if (!response.ok) {
      notifyError("Failed to create invoice report.");
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Telpay-PAD-Export.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    notifySuccess("Invoice report created successfully.");
  }
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };
  const handleSearchChange = (e) => {
    setSearchInvoiceId(e.target.value);
  };
  const handleSearchChangStatus = (e) => {
    setSearchStatus(e.target.value);
  };
  const handleStatusChange = async (invoiceId, newStatus) => {
    try {
      const updatedInvoice = { ...invoiceSummary.find(inv => inv.invoice_id === invoiceId), invoice_status: newStatus };
      const response = await fetch(`${API_ENDPOINTS.INVOICE}${invoiceId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`
        },
        body: JSON.stringify(updatedInvoice)
      });
  
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to update invoice');
      }
      notifySuccess(responseData.success);
      InvoiceData(); // Refresh data
    } catch (error) {
      notifyError(`Error: ${error.message}`);
    }
  };
  const toggleEditStatus = (invoiceId) => {
    setEditingStatusId(editingStatusId === invoiceId ? null : invoiceId);
  };
  const getStatusClass = (status) => {
    switch (status) {
      case 'VERIFIED':
        return 'text-info';
      case 'CANCELLED':
        return 'text-danger';
      case 'CREATED':
        return 'text-warning';
      case 'SEND':
        return 'text-primary';
      case 'PAID':
        return 'text-success';
      default:
        return 'text-standard';
    }
  };
  const sendEmail = async (invoiceId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to send this email?</p>
            <div className='custom-ui-buttons'>
              <button
                onClick={async () => {
                  try {
                    let invoice_id = invoiceId;
                    let keyword = "send_email_invoice";
                    const response = await fetch(API_ENDPOINTS.INVOICE_DATA, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`
                      },
                      body: JSON.stringify({ keyword, invoice_id })
                    });
  
                    const responseData = await response.json();
                    if (!response.ok) {
                      throw new Error(responseData.error || 'Failed to send email');
                    }
                    notifySuccess("Email sent successfully.");
                    InvoiceData();
                  } catch (error) {
                    notifyError(`Error: ${error.message}`);
                  }
                  onClose();
                }}
                className='custom-ui-button custom-ui-button-yes'
              >
                Yes, Send it!
              </button>
              <button 
                onClick={onClose} 
                className='custom-ui-button custom-ui-button-no'
              >
                No
              </button>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <>
    <Notification />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-md-3">
            <div className="card mb-0">
              <div className="card-header pb-2 p-3 mb-2">
                <div className="row">
                  <div className="col-6 d-flex align-items-center">
                    <h6 className="mb-2">Create Invoices</h6>
                  </div>
                  <div className="col-6 text-end">
                    <div className="mb-0">Summary Count :{summaryCount}</div>
                  </div>
                </div>
                <hr className="horizontal dark my-2" />
              </div>
              <div className="card-body p-4 text-center">
              {isLoading ? (
                    <div className="btn bg-gradient-primary mb-0 d-flex justify-content-center align-items-center disabled" onClick={GenerateInvoice}><ClipLoader color={"white"} loading={isLoading} size={30} />&nbsp;&nbsp;Create Invoices</div>
                  ) : (
                    <div className="btn bg-gradient-primary mb-3 d-flex justify-content-center align-items-center" onClick={GenerateInvoice}><i className="material-icons text-sm">add</i>&nbsp;&nbsp;Create Invoices</div>
                  )}
              </div>
            </div>
          </div>
          <div className="custom-col-4-5">
            <div className="card mb-4">
            <div className="card-header pb-0 p-3 mb-3">
                <div className="row">
                  <div className="col-6 d-flex align-items-center">
                    <h6 className="mb-3">Send Invoices</h6>
                  </div>
                  <div className="col-6 text-end">
                    <div className="mb-0">Total Verified Invoices : {verifiedInvoices}</div>
                  </div>
                  <hr className="horizontal dark my-0" />
                </div>
              </div>
              <div className="card-body p-3">
                    <div className="row">
                      <div className="col-md-4 text-center">
                        <div className="card card-body border card-plain border-radius-md ">
                          <h6 className='text-center'>Invoice Date</h6>
                          <select 
                            className="form-control date-dropdown" 
                            value={invoiceSelectedDate} 
                            onChange={(e) => setInvoiceSelectedDate(e.target.value)}
                          >
                            <option value="">Select Date</option>
                            {distinctInvoiceDates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                          </div>
                      </div>
                      <div className="col-md-5 text-center">
                        <div className="card card-body border card-plain border-radius-md ">
                        <h6 className='text-center'>Send Verified Emails</h6>
                        <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={SendVerifiedEmails}>Send</button>
                          </div>
                      </div>
                      <div className="col-md-3 text-center">
                        <div className="card card-body border card-plain border-radius-md ">
                        <h6 className='text-center'>Report</h6>
                        <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={GenerateReport}>Generate</button>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="custom-col-4-5">
            <div className="card mb-4">
            <div className="card-header pb-0 p-3 mb-3">
                <div className="row">
                  <div className="col-6 d-flex align-items-center">
                    <h6 className="mb-3">Send Reminder Text</h6>
                  </div>
                  <div className="col-6 text-end">
                    <div className="mb-0">For Non PAD Stores</div>
                  </div>
                  <hr className="horizontal dark my-0" />
                </div>
              </div>
              <div className="card-body p-3">
                    <div className="row">
                      <div className="col-md-4 text-center">
                        <div className="card card-body border card-plain border-radius-md ">
                          <h6 className='text-center'>Summary Date</h6>
                          <select 
                            className="form-control date-dropdown" 
                            value={summarySelectedDate} 
                            onChange={(e) => setSummarySelectedDate(e.target.value)}
                          >
                            <option value="">Select Date</option>
                            {distinctInvoiceDates.map((date, index) => (
                              <option key={index} value={date}>{date}</option>
                            ))}
                          </select>
                           
                          </div>
                      </div>
                      <div className="col-md-4 text-center">
                        <div className="card card-body border card-plain border-radius-md ">
                        <h6 className='text-center'>Send Text</h6>
                        <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={SendTextMessage}>Send</button>
                          </div>
                      </div>
                      <div className="col-md-4 text-center">
                        <div className="card card-body border card-plain border-radius-md ">
                        <h6 className='text-center'>PAD Report</h6>
                        <button className="btn btn-xsm bg-gradient-primary w-100 mb-0 toast-btn" type="button" onClick={GeneratePADReport}>Generate</button>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        
        
      </div>
      
      <div className="row">
        <div className="col-md-12 mt-1">
          <div className="card">
            
            <div className="card-header pb-0 px-3">
            <div className="row mb-3">
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Name"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange} 
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Filter by Period"
                  name="period"
                  value={filters.period}
                  onChange={handleFilterChange}
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control text-lg p-1"
                  placeholder="Search by Invoice ID"
                  value={searchInvoiceId}
                  onChange={handleSearchChange}
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                />
              </div>
              <div className="col-md-3">
                <select
                  className="form-control text-lg p-1"
                  value={searchStatus}
                  onChange={handleSearchChangStatus}
                  style={{border: '1px solid #ccc', borderRadius: '5px'}}
                >
                  <option value="">Search by Status</option>
                  {statusOptions.map((status, index) => (
                    <option key={index} value={status}>{status}</option>
                  ))}
                </select>
              </div>
            </div>
              <h6 className="mb-0">Invoice Summary</h6>
            </div>
            <div className="card-body pt-4 p-3">
            <div className="table-responsive height_table_max">
            <table className="table align-items-center mb-0">
                <thead>
                    <tr>
                    <th className="text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>
                      Name
                    </th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Invoice ID</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Created Date</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Payment Due</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Billing Period</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Amount</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Status</th>
                    <th className="text-center text-uppercase text-primary text-xs font-weight-bolder opacity-10" style={{width: '10px'}}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInvoiceSummary.map((summary) => (
                      <tr key={summary.invoice_id}>
                        <td>
                          <div className="d-flex px-2 py-1">
                            <div className="d-flex flex-column justify-content-center">
                              <h6 className={`mb-0 text-sm`} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                {summary.invoice_status === 'CREATED' && <i className="fas fa-tag text-warning mx-1"></i>}
                                {summary.is_dynamic_billing && <i className="fas fa-money-bill-wave text-info mx-1" title="Payment Transferred"></i>}
                                {summary.is_dynamic_billing && <i className="fas fa-exchange-alt text-success mx-1" title="Dynamic Billing"></i>}
                                {summary.is_dynamic_billing && <i className="fas fa-money-bill-alt text-success mx-1" title="Dynamic Billing"></i>}
                                {getStoreNameById(summary.invoice_store_id)} 
                                {summary.is_dynamic_billing && summary.invoice_Delivery_count && <span> (On-Demand Delivery Count: {summary.invoice_Delivery_count})</span>}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            <span className="text-xs font-weight-bold"><a href={`/invoices/${summary.invoice_id}`} target="_blank">{summary.invoice_id}</a></span>
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          <span className="text-xs font-weight-bold">{summary.invoice_date}</span>
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          <span className="text-xs font-weight-bold">{summary.invoice_Payment_due}</span>
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          <span className="text-xs font-weight-bold">{summary.invoice_billing_period}</span>
                        </td>
                        <td className="align-middle text-center text-sm" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          <span className="text-xs font-weight-bold">
                            Sub Total: <strong className='text-primary'>${summary.invoice_amount_due}</strong><br/ >
                            GST: <strong className='text-primary'>${summary.invoice_gst_total}</strong> PST: <strong className='text-primary'>${summary.invoice_pst_total}</strong><br/ >
                            Total: <strong className='text-primary'>${summary.invoice_total_amount_due}</strong>
                          </span>
                        </td>
                        <td className="align-middle text-center text-sm">
                          {editingStatusId === summary.invoice_id ? (
                            <select
                              className={`form-control px-3 ${getStatusClass(summary.invoice_status)}`}
                              value={summary.invoice_status}
                              onChange={(e) => handleStatusChange(summary.invoice_id, e.target.value)}
                              onBlur={() => toggleEditStatus(null)}
                            >
                              {statusOptions.map((status) => (
                                <option key={status} value={status} className={`text-primary ${getStatusClass(status)}`}>{status} </option>
                              ))}
                            </select>
                          ) : (
                            <span
                              className={`text-xs font-weight-bold ${getStatusClass(summary.invoice_status)}`}
                              onClick={() => toggleEditStatus(summary.invoice_id)}
                              style={{cursor: 'pointer'}}
                            >
                              {summary.invoice_status}
                            </span>
                          )}
                        </td>
                        <td className="align-middle text-center text-sm">
                          <button 
                            className="btn btn-edit text-primary" 
                            onClick={() => sendEmail(summary.invoice_id)}
                            disabled={summary.invoice_status !== 'VERIFIED'} // Button enabled only if status is 'VERIFIED'
                          >
                            <i className="material-icons" style={{ fontSize: '40px' }}>
                              {summary.invoice_status === 'SEND' || summary.invoice_status === 'PAID' ? 'mark_email_read' : 'forward_to_inbox'}
                            </i>
                          </button>
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </div> 
    </>
  )
}

export default Invocie