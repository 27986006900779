import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_ENDPOINTS } from '../settings.js';
import ClipLoader from 'react-spinners/ClipLoader';
import Notification, { notifyInfo, notifySuccess, notifyWarning, notifyError } from '../popups/Notification';

const InvoicePdfPage = () => {
    const { invoiceId } = useParams();
    const [invoiceData, setInvoiceData] = useState(null);
    const [storeSummaryData, setStoreSummaryData] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);
    const [previousMonth, setPreviousMonth] = useState(null);
    const [extraKMCharged, setExtraKMCharged] = useState(null);
    const [totalAmount, setTotalAmount] = useState(null);
    const [storeData, setStoreData] = useState(null);
    const [previousMonthDeliveriesCount, setPreviousMonthDeliveriesCount] = useState(null);
    const [perOrderFeeAmount, setPerOrderFeeAmount] = useState(null);
    const [firstReminderCallAcceptFee, setFirstReminderCallAcceptFee] = useState(null);
    const [secondReminderCallAcceptFee, setSecondReminderCallAcceptFee] = useState(null);
    const [firstReminderCallRequestFee, setFirstReminderCallRequestFee] = useState(null);
    const [secondReminderCallRequestFee, setSecondReminderCallRequestFee] = useState(null);
    const [reminderCallDispatchAcceptFee, setReminderCallDispatchAcceptFee] = useState(null);
    const [reminderCallDispatchRequestFee, setReminderCallDispatchRequestFee] = useState(null);
    const [addressCorrectionAmount, setAddressCorrectionAmount] = useState(null);
    const [currencyCode, setCurrencyCode] = useState(null);
    const [currencySymbol, setCurrencySymbol] = useState(null);
    let [authTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    useEffect(() => {
        const fetchInvoiceData = async () => {
            try {
                const response = await fetch(`${API_ENDPOINTS.INVOICE_DATA}?invoice_id=${invoiceId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch invoice data');
                }
                const data = await response.json();
                setInvoiceData(data.invoice_data[0]);
                setStoreSummaryData(data.store_summary_data[0]);
                setStoreData(data.store_data);
                setCurrentMonth(data.Current_Month);
                setPreviousMonth(data.Previous_Month);
                setExtraKMCharged(data.extra_KM_Charged);
                setTotalAmount(data.Total_Amount);
                setPreviousMonthDeliveriesCount(data.previous_month_deliveries_count);
                setPerOrderFeeAmount(data.per_order_fee_amount);
                setFirstReminderCallAcceptFee(data.first_reminder_call_accept_fee);
                setSecondReminderCallAcceptFee(data.second_reminder_call_accept_fee);
                setFirstReminderCallRequestFee(data.first_reminder_call_request_fee);
                setSecondReminderCallRequestFee(data.second_reminder_call_request_fee);
                setReminderCallDispatchAcceptFee(data.reminder_call_dispatch_accept_fee);
                setReminderCallDispatchRequestFee(data.reminder_call_dispatch_request_fee);
                setAddressCorrectionAmount(data.address_correction_amount);
                setCurrencyCode(data.currency_code);
                setCurrencySymbol(data.currency_symbol);
            } catch (error) {
                notifyError(error.message);
            }
        };

        fetchInvoiceData();
    }, [invoiceId]);
      
  return (invoiceData && storeData ?
    <div id="invoicePage">
    <meta charSet="utf-8" />
    <style dangerouslySetInnerHTML={{__html: `
    /* reset */
    *
    {
        border: 0;
        box-sizing: content-box;
        font-family: inherit;
        list-style: none;
        margin: 0;
        padding: 0;
        text-decoration: none;
        vertical-align: top;
    }
    p{
        margin-bottom:5px !important;
        margin-top:5px !important;
    }
    table { border-spacing: 0px; }
    table.meta th, table.meta td {
        border-width: 1px; 
        padding: 0.5em; 
        position: relative; 
        text-align: left; 
        font-size: 13px; 
        border-radius: 0.25em; 
        border-style: solid;
    }
    table.meta th {
        background: #EEE; 
        border-color: #BBB;
    }
    table.meta td {
        border-color: #DDD;
    }

    /* page */
    html {
        font: 16px/1 'Open Sans', sans-serif; 
        overflow: auto; 
        background: #999; 
        cursor: default;
    }

    body {
        box-sizing: border-box; 
        min-height: 10.5in; 
        margin: 0 auto; 
        overflow: hidden; 
        padding: 15px 30px 10px 30px; 
        width: 9.35in; 
        background: #FFF; 
        border-radius: 1px;
    }

    /* header */
    header:after {
        clear: both; 
        content: ""; 
        display: table; 
        margin-bottom: 0;
    }

    header h1 {
        font: bold 100% sans-serif; 
        letter-spacing: 0.5em; 
        text-align: center; 
        text-transform: uppercase;  
        background: #000; 
        border-radius: 0.25em; 
        color: #FFF; 
        margin: 0 0 0.5em; 
        padding: 0.5em 0;
    }

    header h2 {
        font-size: large; 
        font-weight: bold;
    }

    header address {
        float: right; 
        font-size: 80%; 
        font-weight: bold; 
        line-height: 1.25; 
        margin: 0 1em 1em 0;
    }

    header address p {
        margin-bottom: 5px; 
        text-align: right;
    }

    header span, header img {
        float: left; 
        margin: 0 0 1em 1em; 
        max-height: 25%; 
        max-width: 60%; 
        position: absolute;
    }

    header img {
        max-height: 7%;
    }

    /* article */
    article, article address, table.meta{
        margin: 0px; 
        margin-top: 2px;
        margin-bottom: 15px;
    }

    article:after {
        clear: both; 
        content: ""; 
        display: table;
    }

    /* table meta & balance */
    table.meta, table.balance {
        float: right; 
        width: 100%; 
        font-family: Geneva, Verdana, sans-serif;
    }

    table.meta:after, table.balance:after {
        clear: both; 
        content: ""; 
        display: table;
    }

    table.balance {
        width: 40%; 
        font-family: Geneva, Verdana, sans-serif;
    }

    /* table items */
    
    table.inventory {
        clear: both; 
        width: 100%;  
        font-family: Geneva, Verdana, sans-serif; 
        font-size: 1em; 
        border-spacing: 0px;
        color: black; 
        margin-top: 50px !important;
    }

    table.inventory th {
        background: #000; 
        color: #FFF; 
        padding: 5px;
    }
    table.inventory td:first-child {
        text-align: left;
    }

    table.inventory th:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    table.inventory th:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    table.inventory td {
        text-align: center; 
        padding: 3px;
        font-size:15px;
    }
    

    /* aside */
    aside h2 {
        font: bold 90% sans-serif; 
        letter-spacing: 0.5em; 
        text-align: center; 
        text-transform: uppercase;  
        border: none; 
        border-width: 0 0 1px; 
        margin: 0 0 1em; 
        text-align: left; 
        font-family: sans-serif; 
        letter-spacing: normal;
        border-color: #999; 
        border-bottom-style: solid; 
        font-size: 13px;
    }

    aside p {
        font-family: sans-serif; 
        font-size: 13px;
    }

    /* javascript */
    .child {
        float: left; 
        vertical-align: middle; 
        width: 50%;
    }
    `}} />
    <header>
      <h1>Invoice</h1>
      <address>
        <h6 style={{ textAlign: 'right', margin: '0' }}>iOrders Technology Services Inc</h6>
        <p style={{fontSize: '12px', margin: '0',color: '#000'}}>Regina,Saskatchewan, CA</p>
        <p style={{fontSize: '12px', margin: '0',color: '#000'}}>306-545-180</p>
        <p style={{fontSize: '12px', margin: '0',color: '#000'}}>www.iorders.ca</p>
      </address>
        <img 
            alt="logo" 
            src="https://uploads-ssl.webflow.com/6096dc3b214fe8475c512ee9/6098625541ae1722ffa8c837_iOrders%20Logo-01.png" 
            
        />
    </header>
    <article style={{fontSize: '15px'}}>
      <div className="parent">
        <div className="child" >
          <h6 style={{ margin: '0', color: '#000'}}>Bill To:</h6>
          <p id="company_name" style={{fontSize: '15px', margin: '0', color: '#000', fontWeight: 'bold'}}><br />{storeData.store_name}</p>
          <p id="company_add_1" style={{fontSize: '15px', margin: '0', color: '#000'}}>{invoiceData.invoice_Store_add1}</p>
          <p id="company_add_2" style={{fontSize: '15px', margin: '0', color: '#000'}}>{invoiceData.invoice_Store_add2}</p>
          <p style={{fontSize: '15px', margin: '0', color: '#000'}}>Canada</p>
        </div>
        <div className="child">
          <table className="meta">
            <tbody style={{color:'black'}}>
                <tr>
                <th style={{ padding: '5px', margin: '5px', width: '50%' }}>Invoice No :</th>
                <td id="invoice_no" style={{ padding: '5px', margin: '5px' }}>{invoiceData.invoice_id}</td>
              </tr>
              <tr>
                <th style={{ padding: '5px', margin: '5px', width: '50%' }}>Invoice Date</th>
                <td id="invoice_date" style={{ padding: '5px', margin: '5px' }}>{invoiceData.invoice_date}</td>
              </tr>
              <tr>
                <th style={{ padding: '5px', margin: '5px', width: '50%' }}>Payment Due</th>
                <td id="payment_due" style={{ padding: '5px', margin: '5px' }}>{invoiceData.invoice_Payment_due}</td>
              </tr>
              <tr>
                <th style={{ padding: '5px', margin: '5px', width: '50%' }}>Payment Period</th>
                <td id="payment_period" style={{ padding: '5px', margin: '5px' }}>{invoiceData.invoice_billing_period}</td>
              </tr>
              <tr >
                <th style={{ fontWeight: 'bold', padding: '5px', margin: '5px', width: '50%' }}>Amount Due</th>
                <td id="amount_due" style={{ fontWeight: 'bold', padding: '5px', margin: '5px' }}>{invoiceData.invoice_total_amount_due}</td>
              </tr>
            </tbody>
            </table>
        </div>
      </div>
      <div id="_row1_">
      </div><div id="_row2_">
      </div><div id="_row3_">
      </div><div id="_row4_">
      </div>
      <table className="inventory">
        <thead className='inventory-head'>
          <tr >
            <th style={{width: '50%'}}>
              Items
            </th>
            <th style={{width: '20%', textAlign: 'center'}}>
            Quantity
            </th>
            <th style={{width: '20%', textAlign: 'center'}}>
            Price
            
            </th>
            <th style={{width: '20%', textAlign: 'center'}}>
                Amount
            </th>
            
          </tr>
        </thead>
        <tbody>
        {invoiceData.invoice_Delivery_count > 0 && !invoiceData.is_dynamic_billing && (
            <tr>
            <td><p id="row_1_col_1">Delivery Service</p><p id="row_1_col_1_sub" style={{fontSize: '12px'}}>iOrders Delivery Service-{invoiceData.invoice_billing_period}</p></td>
            <td id="row_1_col_2">{invoiceData.invoice_Delivery_count}</td>
            <td id="row_1_col_3">{currencySymbol}{invoiceData.invoice_Delivery_rate}</td>
            <td id="row_1_col_4">{currencySymbol}{(invoiceData.invoice_Delivery_rate * invoiceData.invoice_Delivery_count).toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_count > 0 && invoiceData.is_dynamic_billing && (
            <tr>
            <td><p id="row_1_col_1">Delivery Service (On Demand)</p><p id="row_1_col_1_sub" style={{fontSize: '12px'}}>iOrders Delivery Service-{invoiceData.invoice_billing_period}</p></td>
            <td id="row_1_col_2">{invoiceData.invoice_Delivery_count}</td>
            <td id="row_1_col_3">{currencySymbol}{invoiceData.invoice_Delivery_rate}</td>
            <td id="row_1_col_4">{currencySymbol}{(invoiceData.invoice_Delivery_rate * invoiceData.invoice_Delivery_count).toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_tip > 0 && (
            <tr>
            <td><p id="row_2_col_1">Tip Collected</p><p id="row_2_col_1_sub" style={{fontSize: '12px'}}>Tip Collected - Online/Machine/Declared</p></td>
            <td id="row_2_col_2">1</td>
            <td id="row_2_col_3">{currencySymbol}{invoiceData.invoice_Delivery_tip}</td>
            <td id="row_2_col_4">{currencySymbol}{invoiceData.invoice_Delivery_tip.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_sub_fee > 0 && (
            <tr>
            <td><p id="row_3_col_1">iOrders Delivery Subscription Fee</p><p id="row_3_col_1_sub" style={{fontSize: '12px'}}>{currentMonth}</p></td>
            <td id="row_3_col_2">1</td>
            <td id="row_3_col_3">{currencySymbol}{invoiceData.invoice_Delivery_sub_fee}</td>
            <td id="row_3_col_4">{currencySymbol}{invoiceData.invoice_Delivery_sub_fee.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Online_sub_fee > 0 && (
            <tr>
            <td><p id="row_4_col_1">iOrders Online Subscription Fee</p><p id="row_4_col_1_sub" style={{fontSize: '12px'}}>{currentMonth}</p></td>
            <td id="row_4_col_2">1</td>
            <td id="row_4_col_3">{currencySymbol}{invoiceData.invoice_Online_sub_fee}</td>
            <td id="row_4_col_4">{currencySymbol}{invoiceData.invoice_Online_sub_fee.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Website_hosting_fee > 0 && (
            <tr>
            <td><p id="row_5_col_1">Website Hosting Charge</p></td>
            <td id="row_5_col_2">1</td>
            <td id="row_5_col_3">{currencySymbol}{invoiceData.invoice_Website_hosting_fee}</td>
            <td id="row_5_col_4">{currencySymbol}{invoiceData.invoice_Website_hosting_fee.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Tablet_rent_fee > 0 && (
            <tr>
            <td><p id="row_6_col_1">Tablet Renting Charge</p></td>
            <td id="row_6_col_2">1</td>
            <td id="row_6_col_3">{currencySymbol}{invoiceData.invoice_Tablet_rent_fee}</td>
            <td id="row_6_col_4">{currencySymbol}{invoiceData.invoice_Tablet_rent_fee.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Marketing_services_fee > 0 && (
            <tr>
            <td><p id="row_7_col_1">Marketing Services Fee</p></td>
            <td id="row_7_col_2">1</td>
            <td id="row_7_col_3">{currencySymbol}{invoiceData.invoice_Marketing_services_fee}</td>
            <td id="row_7_col_4">{currencySymbol}{invoiceData.invoice_Marketing_services_fee.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Misc_charge > 0 && (
            <tr>
            <td><p id="row_8_col_1">Extra Billable Charge - Miscellaneous</p><p id="row_8_col_1_sub" style={{fontSize: '12px'}}>{invoiceData.invoice_Misc_desc}</p></td>
            <td id="row_8_col_2">1</td>
            <td id="row_8_col_3">{currencySymbol}{invoiceData.invoice_Misc_charge}</td>
            <td id="row_8_col_4">{currencySymbol}{invoiceData.invoice_Misc_charge.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_mrb > 0 && (
            <tr>
            <td><p id="row_8_col_1">Extra Billable Charge </p><p id="row_8_col_1_sub" style={{fontSize: '12px'}}>Machine Return Bonus</p></td>
            <td id="row_8_col_2">1</td>
            <td id="row_8_col_3">{currencySymbol}{invoiceData.invoice_Delivery_mrb}</td>
            <td id="row_8_col_4">{currencySymbol}{invoiceData.invoice_Delivery_mrb.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_ocb > 0 && (
            <tr>
            <td><p id="row_9_col_1">Extra Billable Charge</p><p id="row_9_col_1_sub" style={{fontSize: '12px'}}>Outside City Bonus</p></td>
            <td id="row_9_col_2">1</td>
            <td id="row_9_col_3">{currencySymbol}{invoiceData.invoice_Delivery_ocb}</td>
            <td id="row_9_col_4">{currencySymbol}{invoiceData.invoice_Delivery_ocb.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_frb > 0 && (
            <tr>
            <td><p id="row_10_col_1">Extra Billable Charge</p><p id="row_10_col_1_sub" style={{fontSize: '12px'}}>Food Return Bonus</p></td>
            <td id="row_10_col_2">1</td>
            <td id="row_10_col_3">{currencySymbol}{invoiceData.invoice_Delivery_frb}</td>
            <td id="row_10_col_4">{currencySymbol}{invoiceData.invoice_Delivery_frb.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_awb > 0 && (
            <tr>
            <td><p id="row_11_col_1">Extra Billable Charge</p><p id="row_11_col_1_sub" style={{fontSize: '12px'}}>Address Wrong Bonus</p></td>
            <td id="row_11_col_2">1</td>
            <td id="row_11_col_3">{currencySymbol}{invoiceData.invoice_Delivery_awb}</td>
            <td id="row_11_col_4">{currencySymbol}{invoiceData.invoice_Delivery_awb.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_cob > 0 && (
            <tr>
            <td><p id="row_12_col_1">Extra Billable Charge</p><p id="row_12_col_1_sub" style={{fontSize: '12px'}}>Catering Order Bonus</p></td>
            <td id="row_12_col_2">1</td>
            <td id="row_12_col_3">{currencySymbol}{invoiceData.invoice_Delivery_cob}</td>
            <td id="row_12_col_4">{currencySymbol}{invoiceData.invoice_Delivery_cob.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_wtb > 0 && (
            <tr>
            <td><p id="row_13_col_1">Extra Billable Charge</p><p id="row_13_col_1_sub" style={{fontSize: '12px'}}>Waiting Time Bonus</p></td>
            <td id="row_13_col_2">1</td>
            <td id="row_13_col_3">{currencySymbol}{invoiceData.invoice_Delivery_wtb}</td>
            <td id="row_13_col_4">{currencySymbol}{invoiceData.invoice_Delivery_wtb.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.first_reminder_call_accept_fees > 0 && (
            <tr>
            <td><p id="row_15_col_1">First Reminder Call</p><p id="row_15_col_1_sub" style={{fontSize: '12px'}}>Delivery Order Not Accepted</p></td>
            <td id="row_15_col_2">{invoiceData.first_reminder_call_accept_count}</td>
            <td id="row_15_col_3">{currencySymbol}{firstReminderCallAcceptFee}</td>
            <td id="row_15_col_4">{currencySymbol}{invoiceData.first_reminder_call_accept_fees.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.second_reminder_call_accept_fees > 0 && (
            <tr>
            <td><p id="row_16_col_1">Second Reminder Call</p><p id="row_16_col_1_sub" style={{fontSize: '12px'}}>Delivery Order Not Accepted</p></td>
            <td id="row_16_col_2">{invoiceData.second_reminder_call_accept_count}</td>
            <td id="row_16_col_3">{currencySymbol}{secondReminderCallAcceptFee}</td>
            <td id="row_16_col_4">{currencySymbol}{invoiceData.second_reminder_call_accept_fees.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.first_reminder_call_request_fees > 0 && (
            <tr>
            <td><p id="row_17_col_1">First Reminder Call</p><p id="row_17_col_1_sub" style={{fontSize: '12px'}}>Delivery Order Not Requested</p></td>
            <td id="row_17_col_2">{invoiceData.first_reminder_call_request_count}</td>
            <td id="row_17_col_3">{currencySymbol}{firstReminderCallRequestFee}</td>
            <td id="row_17_col_4">{currencySymbol}{invoiceData.first_reminder_call_request_fees.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.second_reminder_call_request_fees > 0 && (
            <tr>
            <td><p id="row_18_col_1">Second Reminder Call</p><p id="row_18_col_1_sub" style={{fontSize: '12px'}}>Delivery Order Not Requested</p></td>
            <td id="row_18_col_2">{invoiceData.second_reminder_call_request_count}</td>
            <td id="row_18_col_3">{currencySymbol}{secondReminderCallRequestFee}</td>
            <td id="row_18_col_4">{currencySymbol}{invoiceData.second_reminder_call_request_fees.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.reminder_call_dispatch_accept_fees > 0 && (
            <tr>
            <td><p id="row_19_col_1">Calls from Dispatch</p><p id="row_19_col_1_sub" style={{fontSize: '12px'}}>Delivery Order Not Accepted</p></td>
            <td id="row_19_col_2">{invoiceData.reminder_call_dispatch_accept_count}</td>
            <td id="row_19_col_3">{currencySymbol}{reminderCallDispatchAcceptFee}</td>
            <td id="row_19_col_4">{currencySymbol}{invoiceData.reminder_call_dispatch_accept_fees.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.reminder_call_dispatch_request_fees > 0 && (
            <tr>
            <td><p id="row_20_col_1">Calls from Dispatch</p><p id="row_20_col_1_sub" style={{fontSize: '12px'}}>Delivery Order Not Requested</p></td>
            <td id="row_20_col_2">{invoiceData.reminder_call_dispatch_request_count}</td>
            <td id="row_20_col_3">{currencySymbol}{reminderCallDispatchRequestFee}</td>
            <td id="row_20_col_4">{currencySymbol}{invoiceData.reminder_call_dispatch_request_fees.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.invoice_Delivery_extra_km > 0 && (
            <tr>
            <td><p id="row_20_col_1">Extra Kilometers Charge</p><p id="row_20_col_1_sub" style={{fontSize: '12px'}}>Bonus Collected for Extra delivery distance</p></td>
            <td id="row_20_col_2">{invoiceData.invoice_Delivery_extra_km}</td>
            <td id="row_20_col_3">{currencySymbol}{invoiceData.invoice_Store_extra_km_rate}</td>
            <td id="row_20_col_4">{currencySymbol}{extraKMCharged.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.per_order_fee > 0 && (
            <tr>
            <td><p id="row_20_col_1">Per Order Fee</p><p id="row_20_col_1_sub" style={{fontSize: '12px'}}>{previousMonth}</p></td>
            <td id="row_20_col_2">{previousMonthDeliveriesCount}</td>
            <td id="row_20_col_3">{currencySymbol}{perOrderFeeAmount}</td>
            <td id="row_20_col_4">{currencySymbol}{invoiceData.per_order_fee.toFixed(2)}</td>
            </tr>
        )}
        {invoiceData.is_dynamic_billing && totalAmount === 0 ? (
            <tr>
            <td><p id="row_20_col_1">Dynamic Billing Applied</p><p id="row_20_col_1_sub" style={{fontSize: '12px'}}>{currentMonth}</p></td>
            <td id="row_20_col_2">1</td>
            <td id="row_20_col_3">{currencySymbol}0</td>
            <td id="row_20_col_4">{currencySymbol}0</td>
            </tr>
        ) : totalAmount === 0 ? (
            <tr>
                <td><p id="row_20_col_1">Free Subcription Plan  </p><p id="row_20_col_1_sub" style={{fontSize: '12px'}}>{currentMonth}</p></td>
                <td id="row_20_col_2">1</td>
            <td id="row_20_col_3">{currencySymbol}0</td>
            <td id="row_20_col_4">{currencySymbol}0</td>
            </tr>
        ) : null}
        {invoiceData.address_correction_fees > 0 && (
            <tr>
                <td><p id="row_15_col_1">Address Correction Calls from Dispatch</p></td>
                <td id="row_15_col_2">{invoiceData.address_correction_count}</td>
                <td id="row_15_col_3">{currencySymbol}{addressCorrectionAmount}</td>
                <td id="row_15_col_4">{currencySymbol}{invoiceData.address_correction_fees.toFixed(2)}</td>
            </tr>
        )}
        </tbody>
      </table><br/><br/>
      <div id="_row1_">
      </div><div id="_row2_">
      </div>
      <table className="balance">
        <tbody style={{color:'black', fontSize: '13px'}}>
          <tr>
            <th>
              <span style={{fontWeight: 'bold'}}>
                Sub Total
              </span>
            </th>
            <td id="sub_total" style={{textAlign: 'right'}}>{currencySymbol} {invoiceData.invoice_amount_due.toFixed(2)}</td>
          </tr>
          {invoiceData.invoice_pst_total > 0 ? (
            <>
            <tr>
              <th id="prov_tax"><span style={{textAlign: 'left'}}>{invoiceData.invoice_pst_code} {invoiceData.invoice_pst_percentage}%:</span></th>
              <td id="sk_pst" style={{textAlign: 'right'}}>{currencySymbol} {invoiceData.invoice_pst_total.toFixed(2)}</td>
            </tr>
            <tr>
              <th id="gst_head">GST {invoiceData.invoice_gst_percentage} %</th>
              <td id="gst" style={{textAlign: 'right'}}>{currencySymbol} {invoiceData.invoice_gst_total.toFixed(2)}</td>
            </tr></>
          ) : (
            <tr>
              <th id="gst_head">GST {invoiceData.invoice_gst_percentage} %</th>
              <td id="gst" style={{textAlign: 'right'}}>{currencySymbol} {invoiceData.invoice_gst_total.toFixed(2)}</td>
            </tr>
          )}
          
          <tr>
            <th style={{borderBottom: '1px solid #000'}} />
            <td style={{borderBottom: '1px solid #000'}}>
            </td>
          </tr>
          <tr>
            <th>Total</th>
            <td id="total" style={{textAlign: 'right'}}>{currencySymbol} {totalAmount}</td>
          </tr>
          <tr>
            <th style={{fontWeight: 'bold'}}>Amount Due({currencyCode})</th>
            <td id="amount_due_final" style={{fontWeight: 'bold',textAlign: 'right'}}>{currencySymbol} {invoiceData.invoice_total_amount_due.toFixed(2)}</td>
          </tr>
        </tbody>
        </table>
    </article>
    <aside>
      <h2>
          Notes / Terms
      </h2>
      <div>
        <p style={{lineHeight: '1'}}>Thanks for your business. If you have pre-authorized payments, this amount will be withdrawn from your bank account on your withdrawal date.</p>
        <p style={{lineHeight: '1'}}>You can pay using any one of the payment modes</p>
        <p style={{lineHeight: '1'}}>1. Interac Email: info@iorders.ca</p>
        <p style={{lineHeight: '1'}}>2. Bank Transfer - Royal Bank of Canada, iOrders Technology Services Inc., Transit Number: 02214 Institution Number: 003, Account Number: 1010081</p>
      </div>
    </aside>
  </div>
  : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <ClipLoader color={"#123abc"} loading={true} size={200} />
    </div>
  );
}

export default InvoicePdfPage;